














































































.drawer-container {
  padding: .5rem;
  div.exportlist {
    display grid
    grid-template-columns: 100px 1fr
    .export-btn {
      min-width 80px
    }
    .optionlist {
          display: flex;
    flex-direction: column;
    }
  }
}
