.lg-header-subtitle {
  font-size: 0.8rem;
  display: block;
}
.text-primary-light {
  color: #617e8c;
}
.bg-primary-light {
  background: #617e8c;
}
.text-primary-lightest {
  color: #d7dfe3;
}
.bg-primary-lightest {
  background: #d7dfe3;
}
html body {
  color: #3d2d2d;
}
/*# sourceMappingURL=src/css/app.css.map */