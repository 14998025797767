p {
  padding-top: 1rem;
}
.drawer-container {
  padding: 1rem;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
.price-banner {
  margin-bottom: 0.5rem;
}
.warning {
  padding: 0;
  font-size: smaller;
  color: #f00;
}
.uomqty {
  width: 70px;
  height: 26px;
}
.unitselect {
  height: 26px;
}
/*# sourceMappingURL=src/pages/pricing/SavePrice.css.map */