










































































































































































































































































.lg-column-chooser {
  padding-top: 1rem;
}
.content {
  display: flex;
  flex-direction: column;
  padding: 1em;
  .exportlist {
    display grid
    grid-template-columns: 100px 1fr
    .export-btn {
      min-width 80px
    }
    .optionlist {
      display: flex;
      flex-direction: column;
    }
  }
}

.warning {
  color: red;
  display: flex;
  justify-content: center;
  padding: 1em;
}

.drawerMenu {
  z-index: 1700;

  .q-btn {
    display: block;
  }
}

.q-item, .q-field {
  padding: 0 20px;
}

.chip {
  width: 100%;
}

.newfilter {
  padding 1rem
  table {
    width: 100%
    border-collapse: collapse;
    th {
      text-align: left
    }
    thead {
      border-bottom: 1px solid gray;
    }
    tbody {
      td {
        padding .5rem 0
      }
    }
    tfoot {
      th {
        padding-top 1rem;
        border-bottom: 1px solid gray;
      }
    }
  }
}
