label {
  padding-left: 0;
  padding-right: 0;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
.drawer-container {
  padding: 1rem;
  position: relative;
}
.drawer-container .rule-info {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}
/*# sourceMappingURL=src/pages/pricing/SaveDrawer.css.map */