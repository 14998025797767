




























































.ellipsis {
  overflow hidden
  text-overflow ellipsis
  .center {
    text-align center;
  }
}
.localizededition {
  width: 100%
  padding: 5px 0;
  >>> .q-field {
    padding: 0
  }
  >>> .q-field--error {
    padding: 5px 0 35px;
  }
}
