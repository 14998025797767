






















































































































































































































































































































































































































































































































.container{
  width: 100%;
  height: 100%;
}

.svg {
}

.link {
  stroke: #555;
  stroke-width: 2px;
}

.node {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text {
    font: 12px sans-serif;
    alignment-baseline: middle;
    user-select : none;
  }

  circle,
  path {
    fill: #FFF;
  }

  &:hover {
    circle,
    path {
      stroke: blue;
    }
  }
}

.node text.material-icons {
  font-family: 'Material Icons';
  font-size:  25px;
}

/*** SELECTED ***/
.selected circle,
.selected path {
  stroke: blue !important;
  fill: rgb(205, 205, 250) !important;
}
.link.selected {
  stroke: blue !important;
  stroke-width: 3px;
}

/*** HIGHLIGHTED ***/

.highlighted circle,
.highlighted path {
  fill: rgb(199, 255, 197);
  stroke: rgba(6, 146, 1, 0.5);
}
.link.highlighted {
  stroke: rgba(6, 146, 1,  0.5);
  stroke-width: 3px;
}

/*** ORANGE HIGHLIGHT FOR UPPER SIBLINGS ***/
.upper-sibling-highlighted circle,
.upper-sibling-highlighted path {
  fill: rgb(253, 225, 173);
  stroke: rgba(255, 140, 0, 0.5);
}

.link.upper-sibling-highlighted {
  stroke: rgba(255, 140, 0, 0.5);
  stroke-width: 3px;
}

/*** ERROR ***/
.error circle,
.error path {
  fill: rgb(255, 233, 233);
  stroke: rgba(255, 0, 0, 0.5);
}

