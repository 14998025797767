.btn {
  margin: 0 0.5em;
}
.content {
  display: flex;
  padding: 1em;
}
.clear {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em 0.5em 0 0;
  font-size: 0.8em;
  cursor: pointer;
}
/*# sourceMappingURL=src/pages/productDetails/Attributes/addFilter.css.map */