.lg-column-chooser {
  padding-top: 1rem;
}
.content {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.content .exportlist {
  display: grid;
  grid-template-columns: 100px 1fr;
}
.content .exportlist .export-btn {
  min-width: 80px;
}
.content .exportlist .optionlist {
  display: flex;
  flex-direction: column;
}
.warning {
  color: #f00;
  display: flex;
  justify-content: center;
  padding: 1em;
}
.drawerMenu {
  z-index: 1700;
}
.drawerMenu .q-btn {
  display: block;
}
.q-item,
.q-field {
  padding: 0 20px;
}
.chip {
  width: 100%;
}
.newfilter {
  padding: 1rem;
}
.newfilter table {
  width: 100%;
  border-collapse: collapse;
}
.newfilter table th {
  text-align: left;
}
.newfilter table thead {
  border-bottom: 1px solid #808080;
}
.newfilter table tbody td {
  padding: 0.5rem 0;
}
.newfilter table tfoot th {
  padding-top: 1rem;
  border-bottom: 1px solid #808080;
}
/*# sourceMappingURL=src/pages/products/drawer/settingsDrawer.css.map */