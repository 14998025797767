


























































































































.lg-dialog-bug {
  height 600px
}
#screenshot {
  padding: 5px;
  position relative
  img {
    width: 100%;
  }
}
