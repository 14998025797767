.fakeDrawer {
  position: absolute;
  right: 0;
  height: 100%;
  width: 300px;
  border-left: 1px solid #808080;
  background-color: #fbfbfb;
  padding: 30px;
}
.fakeDrawer fieldset {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.fakeDrawer fieldset.applyrateform .header {
  text-align: center;
  font-size: 1.2rem;
}
.fakeDrawer fieldset.applyrateform .date {
  text-align: center;
  font-size: 0.8rem;
}
.fakeDrawer fieldset.applyrateform .value {
  text-align: center;
  font-size: 2rem;
}
.ratetooltip .suffix,
.applyrateform .suffix {
  font-size: 0.7rem;
  background-color: #ffa000;
  color: #fff;
  padding: 0.1rem 0.2rem;
  border-radius: 0.2rem;
}
.mainchart {
  position: absolute;
  top: 50px;
  left: 20px;
  right: 330px;
  bottom: 20px;
}
.chart {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}
.error {
  background-color: rgba(255,0,0,0.4);
}
.ratetooltip {
  min-width: 100px;
}
.ratetooltip .header {
  font-weight: bold;
  font-size: 1.2rem;
  background-color: #f0f0f0;
}
.ratetooltip .main .tooltip-time {
  font-size: 0.8rem;
  color: #808080;
}
.ratetooltip .main .tooltip-value {
  text-align: center;
  font-size: 2rem;
}
/*# sourceMappingURL=src/pages/settings/taux/taux.css.map */