.dialog {
  width: 50%;
  max-width: 500px;
  min-width: 300px;
}
.checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.locked {
  cursor: pointer;
}
.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
/*
  padding: 1rem;
  */
  margin-top: 10px;
}
.localizededition {
  margin-bottom: 25px;
}
.treeSelect {
  max-height: 300px;
}
.content {
  padding: 1rem;
}
/*# sourceMappingURL=src/pages/products/drawer/dialog/ConfirmDialog.css.map */