.help-cursor {
  cursor: help;
}
.helper-drawer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  background: #eee;
  z-index: 2999;
  display: none;
}
.helper-drawer.active {
  display: block;
}
.helper-drawer .helper-topic {
  background: #fff;
  height: calc(100vh - 64px);
  overflow-y: scroll;
}
.helper-drawer .helper-topic iframe {
  width: 100%;
  border: 0;
  height: 100%;
}
.helper-drawer-shift {
  right: 300px;
}
.helper-drawer-pad {
  padding-right: 300px;
}
/*# sourceMappingURL=src/components/help/HelperDrawer.css.map */