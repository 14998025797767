.lg-dialog-bug {
  height: 600px;
}
#screenshot {
  padding: 5px;
  position: relative;
}
#screenshot img {
  width: 100%;
}
/*# sourceMappingURL=src/components/BugReport.css.map */