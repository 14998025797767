@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
























































































































































































.drawer-container {
  .lg-rules {
    height: calc(100vh - 164px);
    overflow-y: auto;
    .lg-rule2 {
      display: grid;
      margin: .4rem
      grid-template-areas: "label label label" "activeicon active status" "modifiedicon modified status" "segmentation segmentation status" "action action action";
      grid-template-columns: 20px 1fr 100px;
      /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */

      > div {
        border-bottom: 1px solid #dfdfdf;
        border-right: 1px solid #dfdfdf;
      }
      .label {
        grid-area: label;
        background-color: $primary;
        color: white;
        padding: .4rem;
        span.title {
          font-weight bold
        }
      }
      .status {
        grid-area: status;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 .4rem;
        border-right: 0
      }
      .modified {
        grid-area: modified;
        text-align center
      }
      .modifiedicon {
        grid-area: modifiedicon;
      }
      .activeicon {
        grid-area: activeicon;
      }
      .active {
        grid-area: active;
        text-align center
      }
      .segmentation {
        grid-area: segmentation;
        ul.nodes {
          margin: 0;
          padding: 0 0 0 1rem;
          font-size: .7rem;
          list-style-type: "→";
        }
      }
      .action {
        padding: .4rem;
        grid-area: action;
        display flex
        flex-direction row-reverse
        border-right: 0
        border-bottom: 0
        > button {
          margin-left: .4rem
        }
      }
    }

    .lg-rule {
      padding .4rem
      border-bottom: 1px solid #bbbbbb
      &:hover {
        background: #cccccc
      }
      &:nth-child(even) {
        background white
      }
      .lg-rule-header {
        display grid
        grid-template-columns 1fr 100px
        &.lg-rule-header-title-only {
          grid-template-columns 1fr
        }
        .lg-rule-header-title {
          div:first-child {
            font-weight bold
          }
          div:nth-child(2) {
            font-size 1rem
            color: gray
          }
        }
        > div:nth-child(2) {
          text-align right
        }
      }
      .lg-rule-dates {
        display grid
        grid-template-columns 50% 50%
        .smalldatehead {
          font-weight: bold;
          font-size: 0.8rem;
        }
        .smalldate {
          font-size .8rem
        }
      }
      .lg-rule-activation {
        display grid
        grid-template-columns 50px 1fr
      }
    }
  }
}
.lg-rule-actions {
  padding-right: .5rem
  text-align right
}
