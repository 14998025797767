.addLine {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.topTitle {
  display: block;
  padding: 30px;
  font-size: 2rem;
  opacity: 0.5;
}
.content {
  width: 100%;
}
.error-content {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.header {
  min-width: 400px;
}
.header .title {
  display: flex;
}
.icon {
  cursor: pointer;
}
.cell {
  display: flex;
}
.cell .operatorSelect {
  width: 100px;
}
.cell .tagSelect {
  min-width: 300px;
  width: 100%;
}
/*# sourceMappingURL=src/pages/settings/decisionGrid/PricingMethod.css.map */