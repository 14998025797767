.lg-section-header {
  padding: 5px 1rem;
}
.attribute-list >>> .q-expansion-item-sub-item {
  padding-right: 0.5rem;
}
.attribute-list >>> .q-item {
  padding-left: 0;
}
.attribute-list >>> .q-item .q-item__label {
  font-weight: bold;
}
.leftborder:before {
  content: "";
  border-left: 4px solid #ffa000;
  position: absolute;
  margin-left: -1rem;
  border-radius: 4px;
  top: 0;
  bottom: 0;
}
.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.title .actions {
  display: flex;
}
.edition {
  padding: 0 0em 1rem 0em;
  display: flex;
  justify-content: center;
}
.edition > div.q-card {
  width: 100%;
  margin: 1px;
}
.clickable {
  cursor: pointer;
}
.flex-end {
  justify-content: center;
  width: 100%;
  display: flex;
}
>>> .lg-attribute-set .q-item__label {
  font-variant: small-caps;
}
.addattribute {
  display: inline-block;
  width: 100px;
  padding: 0;
}
/*# sourceMappingURL=src/pages/productDetails/Attributes/Attributes.css.map */