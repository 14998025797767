




































































































































aside.q-layout-drawer {
  background: #f0f0f0;
}
.attr-tile {
  background white
}
