



























































































































































.no-empty {
  border-top: 1px solid gray
  padding 1rem
  display flex
  flex-direction: column
}
.lg-filter-buttons {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #e0e0e0;
    .spacer {
      flex 1
    }
}
