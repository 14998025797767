@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'


























































































































































































































































.fakeDrawer {
  position: absolute;
  right: 0;
  height: 100%;
  width: 300px;
  border-left: 1px solid grey;
  background-color: #FBFBFB;
  padding:30px;
  fieldset {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &.applyrateform {
     .header {
      text-align: center;
      font-size: 1.2rem;
     }
     .date {
      text-align: center;
      font-size: 0.8rem;
     }
     .value {
      text-align: center;
      font-size: 2rem;
     }
    }
  }
}
.ratetooltip .suffix, .applyrateform .suffix {
    font-size: 0.7rem;
    background-color: $secondary;
    color: white;
    padding: 0.1rem 0.2rem;
    border-radius: 0.2rem;
  }
.mainchart {
  position: absolute;
  top: 50px;
  left: 20px;
  right: 330px;
  bottom: 20px;
}

.chart {
  position: relative;
  width: 100%;
  height : 100%;
  display: block;
}

.error {
  background-color: rgba(255, 0, 0, 0.4);
}

.ratetooltip {
  min-width: 100px
  .header {
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #f0f0f0;
  }
  .main {
    .tooltip-time {
      font-size: 0.8rem;
      color: grey;
    }
    .tooltip-value {
      text-align: center;
      font-size: 2rem;
    }
  }
}
