.defaultvalue {
  display: flex;
  gap: 4px;
}
.defaultvalue input {
  flex: 1;
}
.defaultvalue select {
  width: 50px;
}
.drawer-container {
  height: calc(100vh - 240px);
}
.component-container {
  height: 100%;
  min-height: 500px;
}
.checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.locked {
  cursor: pointer;
}
.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.content {
  padding: 1rem;
}
/*# sourceMappingURL=src/components/drawer/attributes/attributesDrawer.css.map */