@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
















































































































































































.finder-container {
  flex-wrap: nowrap;

  .finder-column {
    width: 30vw;
    max-width: 30vw;
    min-width: 30vw;
    border-right: 1px solid $light;
    position: relative;
    div.q-list {
      position relative
      overflow auto
      height 100%
    }
    .finder-fab {
      position: absolute;
      bottom: 50px;
      right: 50px;
    }
  }
}
