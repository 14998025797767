.drawer-container {
  padding: 1rem;
}
.drawer-container .drawer-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}
.drawer-container .drawer-section .drawer-section-title {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}
.checkbox {
  font-size: 12px;
}
/*# sourceMappingURL=src/pages/pricing/SetConsistencyDrawer.css.map */