




















































































































.crc-boundaries, .crc-params {
  width: 100%
  input {
    width: 100%
  }
  .ralign {
    text-align: right;
  }
}
