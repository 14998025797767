































































































































































































































































.modaleCard {
  width:100%;
  height: 100%;
  max-width: 1200px;
  max-height: 80vh;
  overflow: hidden;
}

.cardAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid grey;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.costPlus {
  margin: auto;
  box-sizing: border-box;
  padding: 0px 10px;
  margin-top: -25px;
  height: calc(100% - 140px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  //border: 1px solid red;

  .costPlusScroll {
    box-sizing: border-box;
    height: 100%;
    width: calc(100% - 200px);
    overflow-y: auto;
    overflow-x: auto;
    //border: 1px solid blue;
  }
  .edit{
    height: calc(100% - 30px);
    width: 250px;
    margin-left: 5px;
    border-left: 1px solid rgba(155, 155, 155, 0.678);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .loadConfig{
    display:flex;
    flex-direction:column;
    align-items: center;
    width:90%;
    margin:auto;
   // margin-top:15px;
    margin-bottom:15px;
  }
}

