.file-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 168px - 48px);
}
.file-drop .drop-circle {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/*# sourceMappingURL=src/components/drawer/ImpExp/step1.css.map */