












































































.drawer-padding {
  padding 1rem
}
.results {
  overflow: auto;
  max-height: calc(100vh - 210px);
}
.results, .header {
  display: grid;
  grid-template-columns: 80px 60px auto 32px;

}
.results {
  > div {
  padding: 2px 0;
  border-bottom: 1px solid #dfdfdf;
  }
}
.header {
  border-bottom: 1px solid gray;
  div {
    font-weight: bold
  }
}
.content {
  padding: 1rem
}
.ruleparams-fab {
    right 1rem
    bottom 1rem
}
