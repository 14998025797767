.function-row {
  padding-bottom: 1rem;
  overflow: auto;
}
.function-row .thumbnails,
.function-row .boundaries {
  display: flex;
  flex-wrap: nowrap;
}
.function-row .boundaries .boundary {
  margin-left: 110px;
  width: 100px;
}
.function-block {
  width: 160px;
  min-height: 207px;
  min-width: 160px;
  background: #fff;
  margin-right: 10px;
  position: relative;
}
.function-block .q-card-container {
  padding: 4px;
}
.function-block svg {
  width: 100%;
  height: 150px;
  transform-origin: 50% 50%;
  transform: scale(1, -1);
}
.function-block:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -5px;
  top: 0;
  width: 0;
  border-right: 1px dashed #f00;
  bottom: -10px;
}
.function-block.empty-block {
  display: flex;
  align-items: center;
}
/*# sourceMappingURL=src/pages/pricing/RuleRefine.css.map */