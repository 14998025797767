@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'






































































































































































































































>>> .lg-import-table {
  table.q-table {
    width: 100%;
    max-width 100%;
  }
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  .action {
    .add-user {
      position: absolute;
      right: 30px;
      bottom: 30px;
    }
  }
  .inline-svg {
    width: 100%;
    height: 100%;
    rect.bar-ok {
      fill: $positive;
      stroke: white;
    }
    rect.bar-error {
      fill: $negative;
      stroke: white;
    }
    rect.bar-transmitted {
      stroke white
      fill $green-4
    }
    rect.bar-acknowledged {
      stroke white
      fill $green-6
    }
    rect.bar-active {
      stroke white
      fill $green-8
    }
    rect.bar-waiting {
      stroke white
      fill $green-2
    }
    rect.bar-rejected {
      stroke white
      fill $red-6
    }
    rect.bar-inconsistent {
      stroke white
      fill $red-8
    }
  }
}
