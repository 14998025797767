.drawer-container .lg-rules {
  height: calc(100vh - 164px);
  overflow-y: auto;
}
.drawer-container .lg-rules .lg-rule2 {
  display: grid;
  margin: 0.4rem;
  grid-template-areas: "label label label" "activeicon active status" "modifiedicon modified status" "segmentation segmentation status" "action action action";
  grid-template-columns: 20px 1fr 100px;
/* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
}
.drawer-container .lg-rules .lg-rule2 > div {
  border-bottom: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}
.drawer-container .lg-rules .lg-rule2 .label {
  grid-area: label;
  background-color: #455a64;
  color: #fff;
  padding: 0.4rem;
}
.drawer-container .lg-rules .lg-rule2 .label span.title {
  font-weight: bold;
}
.drawer-container .lg-rules .lg-rule2 .status {
  grid-area: status;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 0.4rem;
  border-right: 0;
}
.drawer-container .lg-rules .lg-rule2 .modified {
  grid-area: modified;
  text-align: center;
}
.drawer-container .lg-rules .lg-rule2 .modifiedicon {
  grid-area: modifiedicon;
}
.drawer-container .lg-rules .lg-rule2 .activeicon {
  grid-area: activeicon;
}
.drawer-container .lg-rules .lg-rule2 .active {
  grid-area: active;
  text-align: center;
}
.drawer-container .lg-rules .lg-rule2 .segmentation {
  grid-area: segmentation;
}
.drawer-container .lg-rules .lg-rule2 .segmentation ul.nodes {
  margin: 0;
  padding: 0 0 0 1rem;
  font-size: 0.7rem;
  list-style-type: "→";
}
.drawer-container .lg-rules .lg-rule2 .action {
  padding: 0.4rem;
  grid-area: action;
  display: flex;
  flex-direction: row-reverse;
  border-right: 0;
  border-bottom: 0;
}
.drawer-container .lg-rules .lg-rule2 .action > button {
  margin-left: 0.4rem;
}
.drawer-container .lg-rules .lg-rule {
  padding: 0.4rem;
  border-bottom: 1px solid #bbb;
}
.drawer-container .lg-rules .lg-rule:hover {
  background: #ccc;
}
.drawer-container .lg-rules .lg-rule:nth-child(even) {
  background: #fff;
}
.drawer-container .lg-rules .lg-rule .lg-rule-header {
  display: grid;
  grid-template-columns: 1fr 100px;
}
.drawer-container .lg-rules .lg-rule .lg-rule-header.lg-rule-header-title-only {
  grid-template-columns: 1fr;
}
.drawer-container .lg-rules .lg-rule .lg-rule-header .lg-rule-header-title div:first-child {
  font-weight: bold;
}
.drawer-container .lg-rules .lg-rule .lg-rule-header .lg-rule-header-title div:nth-child(2) {
  font-size: 1rem;
  color: #808080;
}
.drawer-container .lg-rules .lg-rule .lg-rule-header > div:nth-child(2) {
  text-align: right;
}
.drawer-container .lg-rules .lg-rule .lg-rule-dates {
  display: grid;
  grid-template-columns: 50% 50%;
}
.drawer-container .lg-rules .lg-rule .lg-rule-dates .smalldatehead {
  font-weight: bold;
  font-size: 0.8rem;
}
.drawer-container .lg-rules .lg-rule .lg-rule-dates .smalldate {
  font-size: 0.8rem;
}
.drawer-container .lg-rules .lg-rule .lg-rule-activation {
  display: grid;
  grid-template-columns: 50px 1fr;
}
.lg-rule-actions {
  padding-right: 0.5rem;
  text-align: right;
}
/*# sourceMappingURL=src/pages/pricing/LoadDrawer.css.map */