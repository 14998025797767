

































































































































































































































































































p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
.price-banner {
  margin-bottom .5rem
}
.warning {
  padding 0
  font-size: smaller
  color: red;
}
.uomqty {
  width: 70px
  height 26px
}
.unitselect {
  height: 26px
}
