















































































.pickerrow {
  display: flex;
  gap: 1rem
  .filepicker {
    width: 20rem
    display: inline-block
  }
}
