









































































































































































































































































































.error-info {
  max-height: 250px;
  height: 250px;

  .chart-content {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 300px;

    .css-donut {
      /*border: 1px solid red;*/
    }

    .svg-circle {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    .circle_animation {
      -webkit-animation: css 1s ease-out forwards;
      animation: css 1s ease-out forwards;
      stroke-dasharray: 440;
    }
  }

  .errors-founded {
    max-height: 100px;
    font-size: 2rem;
    display: flex;
    width: 100%;
    justify-content: center;
    opacity: 0.5;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 50px);

  .grid-content {
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    .upload {
      padding: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
