


































































.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  .copyright {
    opacity: 0.5;
    position: absolute;
    bottom: 50px;
    left: 50px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    opacity: 0.5;
  }

  .subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    opacity: 0.5;
  }
}
.maintenance-banner {
  min-width: 600px;
  display: flex;
  align-items: center;
  .maintenance-icon {
    font-size 4rem
  }
  .maintenance-message {
    flex: 1;
  }
  >>> .flip-card {
    font-size: 2rem;
  }
}
