@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'































































































































































































































































































































































































































































































































































































































































































































































input[type="number"] {
  text-align: right;
}
#lg-pricing-spitter {
  .q-splitter__after {
    padding-top: 50px
  }
  .q-splitter__separator > .q-splitter__separator-area {
    right: 0;
  }
  .drawerbar {
    display flex
    > div:first-child {
      display flex
      background-color $grey-3
      flex-direction column
      height: calc(100vh - 51px)
    }
    > div:nth-child(2) {
      flex 1
      overflow: auto
    }
  }
}
.lg-sticky-scroll {
  overflow unset
}
.lg-pagination {
  display flex
  align-items center
  justify-content center
  height: 47px;
  border-top: 1px solid gray;
  position relative
  .lg-pagination-status {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 3rem;
  }
  .lg-actual-pagination {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .lg-time-travel {
    padding-right: 1rem
    padding-left: 1rem
    border-left: 1px solid gray
  }
}
#grid-container {
  height calc(100vh - 100px)
  width 100%
  overflow auto
  display grid
  &.lg-commented-grid {
    height calc(100vh - 158px);

  }

  .grid {
    display flex
    flex-wrap nowrap
    .grid-col {
      width 150px
      min-width 150px
      &.nonpriced {
        .grid-cell {
          &.comparable {
            background: #d2979e;
          }
          &.cell-first {
            &.comparable {
              background: #d2979e;
            }
          }
        }
      }
      &.slavepart {
        .grid-cell {
          background: #a2b5ca;
          &.cell-first {
            background: #a2b5ca;
          }
        }
      }
      &.active-search {
        .grid-cell {
          background: yellow;
          &.cell-first {
            background: yellow;
          }
        }
      }
      &.subnom {
        .grid-cell {
          &.comparable {
            background: #db865e;
          }
          &.cell-first {
            &.comparable {
              background: #db865e;
            }
          }
        }
      }
      .grid-cell {
        height 150px
        overflow hidden
        white-space nowrap
        height auto
        border-right 1px solid gray
        .lg-attr-group {
          min-width 120px
          padding: 0 1rem
          font-variant small-caps
        }
        &.comparable {
          background: #a9a9a9;
          .cell-item:nth-child(even) {
            background: #9a9a9a;
          }
        }
        &.cell-first {
          position sticky
          -webkit-position sticky
          top 0
          background white;
          &.comparable {
            background: #bbb;
            .cell-item:nth-child(even) {
              background: #a9a9a9;
            }
          }
          .photo-cell {
            z-index: 4500;
          }
          .lg-attr-group {
            background: white
            line-height: 1.2rem;
            margin-top: 2px;
          }
          z-index 1400
          li.validated-price {
            height 46px
            line-height: 38px;
            text-align: right;
            padding-right: 4px;
            border-top: 1px solid gray;
          }
        }
        ul {
          padding 0
          margin 0
          list-style none
          li {
            ul.collapsed {
              display: none;
            }
            &.validated-price {
              height 46px
              margin-bottom: 4px;
              .q-if-addon {
                min-width: calc(2.2rem + 4px);
                text-align: left;
                padding-left: 2px;
              }
            }
            &.photo-cell {
              height 106px
              padding-top: 6px;
              text-align center
              position relative
              >.overview {
                display: grid
                align-items stretch
                >div {
                  display: flex
                  align-items: center
                }
                grid-template-columns: 30px 1fr 30px
                grid-template-areas: "purchasealert turnover growing" "numberofparts turnover same" "anomalies turnover shrinking"
                .overview-purchasealert { grid-area: purchasealert; }
                .overview-anomalies { grid-area: anomalies; }
                .overview-numberofparts { grid-area: numberofparts; }
                .overview-growing { grid-area: growing; }
                .overview-shrinking { grid-area: shrinking; }
                .overview-same { grid-area: same; }
                .overview-turnover-margin {
                  grid-area: turnover;
                  display: grid;
                  grid-template-columns: 1fr;
                  grid-template-rows: 1fr 1fr 1fr;
                  grid-template-areas: "margin-validated" "margin" "turnover";
                  .overview-turnover, .overview-margin {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.2em;
                  }
                  .overview-turnover { grid-area: turnover; }
                  .overview-margin { grid-area: margin; }
                  .overview-margin-validated {
                    grid-area: margin-validated;
                    display: flex ;
                    align-items: stretch
                    justify-content: center;
                    > div {
                      flex: .6
                    }
                  }
                }

                margin: 0 6px;
                height 100px
              }
              img {
                height 100px
              }
              .lg-photos-dots {
                position: absolute
                top: 4px
                right 16px
                .lg-photos-dot {
                  background: $grey-4;
                  border: 2px solid white;
                  border-radius: 50%
                  display inline-block
                  width: 10px
                  height: 10px
                  margin-left: 2px
                  &:first-child {
                    margin-left: 0
                  }
                }
              }
              .infozone {
                position: absolute;
                bottom: 0px
                left: 14px;
                font-size: 1rem;
                color: $primary;
              }
            }
            .cell-item {
              height 25px
              padding 0 4px
              position relative
              &.double-line {
                height: 38px
              }
              .cell-string {
                overflow: hidden;
                text-overflow: ellipsis
                text-align center
              }
              .attributehistory {
                position: absolute;
                font-size: 1rem;
                top: 0px;
                left: 4px;
                right: 4px
                bottom: 0;
                z-index: 1001;
              }
              .cell-date, .cell-boolean, .cell-field {
                text-align center
              }
              .cell-field {
                .lg-boolean {
                  margin-top: -.5rem;
                }
                .q-field__control {
                  height 24px
                }
              }
              .cell-amount, .cell-number {
                text-align right
                .cell-unit {
                  min-width: 2.2rem;
                  display inline-block
                  text-align left
                  padding-left: 2px
                }
              }
              .proposed-price {
                position: absolute;
                display: flex;
                justify-content: space-between
                button {
                  /* font-size: 7px;*/
                  font-weight: bold;
                }
              }
              .lg-input-attribute {
                border: 0;
                padding: 0;
                background: transparent;
                text-align: center;
                margin: 0;
                padding-left: .25rem;
              }
              &:nth-child(even) {
                background: lighten($primary, 90%);
              }
            }
            &.attr-set {
              padding-top .6rem
              min-height 2rem
              .attrset-list {
                border-top 1px solid gray
                padding-top .5rem
              }
              &:first-child {
                .attrset-list {
                  border-top none;
                }
              }
            }
          }
        }
      }
      &.col-first {
        background: $primarylightest;
        position sticky
        -webkit-position sticky
        left 0
        z-index 1500
        width auto
        max-width 250px;
        .cell-item {
          text-align right
        }
        .grid-cell.cell-first {
            z-index 1520
        }
        div.attrset-first {
            position: absolute;
            margin-top: -.8rem;
            padding: 2px 10px;
            z-index: 1510;
        }
      }
    }
  }
}
.lg-variation-2.photo-cell:after {
  content: '';
  position: absolute;
  border: 2px solid $positive;
  border-radius: 2px;
  bottom: -7px;
  left: 1rem;
  right: 1rem;
}
.lg-variation-0.photo-cell:after {
  content: '';
  position: absolute;
  border: 2px solid $negative;
  border-radius: 2px;
  bottom: -7px;
  left: 1rem;
  right: 1rem;
}
#rightoggle  {
  z-index 1700
  .q-btn {
    display: block;
  }
}
button.q-btn.q-btn-round.in-row {
  height: 18px;
  width: 18px;
  font-size: auto;
  &.no-sort {
    color: $light !important
  }
  &.sort-asc, &.sort-desc {
    color: $primary !important
  }
}

.savetip {
    position: absolute;
    right: 2px;
    top: 2px;
}
.thingstosave {
  position: absolute;
  right: 6px;
  font-size: 0.6rem;
  top: -7px;
}
.error-container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.lg-part-segment {
  position: absolute;
  top: 3px;
  right: 3px;
}

.animated:active {
  background: lighten($secondary, 20%)
}
.animated:not(:active) {
  animation: shadow-drop-center .2s ease 0s 1 normal none;
}
@keyframes shadow-drop-center {
  0%{
    box-shadow: 0 0 0 0 transparent;
  }
  100%{
    box-shadow: 0 0 20px 0 rgba(0,0,0,.35);
  }
}

.ellipsisAttr{
  overflow:hidden;
  text-overflow: ellipsis;
}

