

























































.login-standby {
  display: none;
  position: absolute;
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color #00000090
  z-index 5000
  div {
    width: 200px;
    text-align: center;
    color: white;
    font-size: 1rem;
  }
  &.login-standby-active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.login-box {
  max-width 400px;
  .align-right {
    text-align right
  }
}
