.locked {
  cursor: pointer;
}
.drawer-panel {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
.bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/*# sourceMappingURL=src/components/drawer/users/usersDrawer.css.map */