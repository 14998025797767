.dialog {
  min-width: 500px;
  width: 100%;
  max-width: 50%;
  max-height: 80%;
  overflow: hidden;
}
.lg-section-content {
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.choose-values-message {
  margin: 20px;
}
.separator {
  height: 1px;
  background-color: #bdbdbd;
  width: 100%;
}
.q-checkbox {
  flex-grow: 0;
}
.lg-sparkline-set {
  background: #e0e0e0;
  width: 60px;
  margin: 5px;
  padding: 5px;
}
.boolean-attribute-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.attribute-information {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.weights-container {
  display: flex;
  flex-direction: column;
}
.weights-container > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.weight-value {
  width: 80px;
  flex-grow: 2;
}
.lg-sparkline-set,
.weight-value {
  flex: 1;
  text-align: center;
}
.test1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.test2 {
  margin: 20px;
}
/*# sourceMappingURL=src/pages/products/drawer/dialog/SelectValuesDialog.css.map */