@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
// app global css in Stylus form

  .lg-header-subtitle {
    font-size: .8rem;
    display: block
  }


.text-primary-light {
  color: $primarylight;
}
.bg-primary-light {
  background: $primarylight;
}
.text-primary-lightest {
  color: $primarylightest;
}
.bg-primary-lightest {
  background: $primarylightest;
}


html {
  body {
    color: $dark;
  }
}