.q-layout-page {
  display: flex;
}
.q-layout-page .finder-container {
  flex: 1;
}
.q-layout-page .file-detail {
  background: #eee;
  min-width: 300px;
  width: 300px;
}
.q-layout-page .file-detail .doughnut {
  width: 100%;
}
.q-layout-page .file-detail .family-fab {
  right: 1rem;
  bottom: 1rem;
}
/*# sourceMappingURL=src/pages/FamilySelection.css.map */