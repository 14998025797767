.settings {
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  position: fixed;
  z-index: 950;
  top: 50px;
  right: 0;
}
.settings .q-btn {
  display: block;
  border-radius: 0;
}
.settings .q-btn:last-child {
  border-bottom-left-radius: 1rem;
}
.settings:hover {
  opacity: 1;
}
.q-th-header:last-child {
  padding-right: 60px;
}
.cell-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.table-top {
  max-height: 20px !important;
  padding: 8px 24px;
}
.pointer {
  cursor: pointer;
}
.grey {
  opacity: 0.4;
}
.grey:hover {
  opacity: 1;
}
.sp {
  padding: 10px;
  cursor: pointer;
}
.fulltable .tablebottom {
  flex: 1;
  display: flex;
  column-gap: 1rem;
}
.fulltable .tablebottom .lgselected {
  padding-left: 1rem;
}
.fulltable .tablebottom .lgpagination {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  column-gap: 1rem;
}
.fulltable .tablebottom .lgpagination .q-pagination {
  flex: 1;
  justify-content: center;
}
.fulltable .tablebottom .lgpagination .lgrowperpage .q-select {
  min-width: 150px;
}
/*# sourceMappingURL=src/pages/products/products.css.map */