.accordeon-params {
  font-weight: bold;
  border-bottom: 1px solid #000;
}
.lg-common-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.lg-common-settings .threshold {
  display: flex;
  gap: 4px;
}
.lg-common-settings .threshold > :first-child {
  flex: 1;
}
/*# sourceMappingURL=src/pages/pricing/RuleProgressive.css.map */