




































































































































.accordeon-params {
    font-weight: bold;
    border-bottom: 1px solid #000;
}
.lg-common-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .threshold {
    display: flex;
    gap: 4px;
    > :first-child {
      flex: 1;
    }
  }
}
