.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.content .copyright {
  opacity: 0.5;
  position: absolute;
  bottom: 50px;
  left: 50px;
}
.content .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  opacity: 0.5;
}
.content .subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  opacity: 0.5;
}
.maintenance-banner {
  min-width: 600px;
  display: flex;
  align-items: center;
}
.maintenance-banner .maintenance-icon {
  font-size: 4rem;
}
.maintenance-banner .maintenance-message {
  flex: 1;
}
.maintenance-banner >>> .flip-card {
  font-size: 2rem;
}
/*# sourceMappingURL=src/pages/Index.css.map */