






























































































































.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}

.attrselect {
    width: 100%;
    height: 2rem;
}
