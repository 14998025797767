


















































p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
  .decision, .condition  {
    box-shadow: 2px 2px 4px 2px #ccc;
  }
  .condition {
    border: 1px solid #ccc;
    .operation {
      padding: .5rem;
    }
    .attribute {
      font-weight: bold;
      padding: .5rem;
      background: #f0f0f0;
    }
    .args {

    }
  }
  .decision {
    font-weight: bold;
    border-radius: 50%
    background: #f0f0f0;
    text-align: center;
    line-height: 1.8rem;
    width: 4rem;
    height: 4rem;
    margin: 1rem auto;
    background: lightgreen;
  }
  .downarrow {
    text-align: center;
    font-size: 2rem;
    margin: 1rem 0;

  }
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
