



































































p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
  height: calc(100vh - 100px);
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
