






































































































.drawerPadding {
  padding: 20px;
}
.title{
  font-size: 1.2em;
}
.selectField {
  width: 75%;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 40px;
}
.addButton {
  display: inline-block;
  margin-left: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: -16px;
  width: 20%;
}
.attributeTable {
  height: calc(100vh - 460px);
  margin-top: 10px;
}

.drawer-container{
  //border:1px solid blue;
  max-height: calc(100% - 50px);
  height : 100%;
  // overflow: hidden;
}
.drawerPadding {
  // border: 1px solid red;
  height: 100%;
  //max-height: calc(100% - 50px);
  //overflow: hidden;
}

.attributeList {
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.attributeElement {
  //border-bottom: 1px solid grey;
  padding: 10px;
  display: flex;
  margin-bottom:5px;
  margin-right: 10px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F2F2F2;
  border-radius: 5px;
}
