
















































































































































































































































































































































































































































































































.checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.locked {
  cursor: pointer;
}

.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*
  padding: 1rem;
  */
  margin-top: 10px;
}

.content {
  padding: 1rem;
}

.imgSection{
  margin-top:15px;
  // border : 1px solid #ccc;
  // border-radius: 5px;
  // padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  p{
    margin:0;
    margin-bottom:5px
  }
}
