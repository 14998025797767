






















































.help-cursor {
  cursor help
}
.helper-drawer {
  position: absolute;
  right 0;
  top: 0;
  height: 100vh;
  width: 300px;
  background: #eeeeee;
  z-index 2999
  display: none;
  &.active {
    display: block;
  }
  .helper-topic {
    background: white;
    height: calc(100vh - 64px)
    overflow-y scroll
    iframe {
      width: 100%;
      border: 0;
      height 100%
    }
  }
}
.helper-drawer-shift {
  right: 300px;
}
.helper-drawer-pad {
  padding-right 300px;
}
