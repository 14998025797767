.drawer-content {
  padding: 0.5rem;
}
.lg-rule-actions {
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.syncattrlist {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}
.lg-banner:not(:first-child) {
  margin-top: 0.5rem;
}
/*# sourceMappingURL=src/pages/products/drawer/resendDrawer.css.map */