.welcome {
  padding: 50px;
}
.q-field {
  padding: 5px;
}
.q-field .label {
  color: #979797;
  line-height: 18px;
  font-size: 0.75rem;
}
.table {
  height: calc(100vh - 51px);
  width: 100%;
  overflow: auto;
}
/* TODO remove on quasar v1.0 https://github.com/quasarframework/quasar/issues/3126 */
.q-stepper-horizontal.q-stepper-contractable > .q-stepper-header {
  min-height: 72px;
}
.q-stepper-horizontal.q-stepper-contractable > .q-stepper-header .q-stepper-tab {
  padding: 24px 0;
}
.q-stepper-horizontal.q-stepper-contractable > .q-stepper-header .q-stepper-tab:not(:last-child) .q-stepper-dot:after {
  display: block !important;
}
.q-stepper-horizontal.q-stepper-contractable > .q-stepper-header .q-stepper-dot {
  margin: 0;
}
.q-stepper-horizontal.q-stepper-contractable > .q-stepper-header .q-stepper-label {
  display: none;
}
/*# sourceMappingURL=src/pages/importExport/import.css.map */