.no-empty {
  border-top: 1px solid #808080;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.lg-filter-buttons {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: #e0e0e0;
}
.lg-filter-buttons .spacer {
  flex: 1;
}
/*# sourceMappingURL=src/pages/products/HeadersFilters/filterBase.css.map */