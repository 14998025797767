.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis .center {
  text-align: center;
}
.localizededition {
  width: 100%;
  padding: 5px 0;
}
.localizededition >>> .q-field {
  padding: 0;
}
.localizededition >>> .q-field--error {
  padding: 5px 0 35px;
}
/*# sourceMappingURL=src/components/attributesDisplay/localizedstring.css.map */