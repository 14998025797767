









































































































































































































































































































































.btnsp {
  margin-left: 3px;
}

.nobtn {
  height: 36px;
}

.doubleBtnEnd {
  display: flex;
  justify-content: flex-end;
}

.stepper {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);

  .q-stepper-header {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }

  .q-stepper-step-inner {
    padding: 0 !important;
    margin: 0;
  }

  .q-stepper-nav {
    margin: 0 !important;
    padding: 0;
  }

  .content {
    height: calc(100vh - 171px);
    overflow: auto;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    padding: 5px;
    bottom: 1rem;
    right: 0;
  }
}
