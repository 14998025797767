.error-info {
  max-height: 250px;
  height: 250px;
}
.error-info .chart-content {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 300px;
}
.error-info .chart-content .svg-circle {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.error-info .chart-content .circle_animation {
  -webkit-animation: css 1s ease-out forwards;
  animation: css 1s ease-out forwards;
  stroke-dasharray: 440;
}
.error-info .errors-founded {
  max-height: 100px;
  font-size: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  opacity: 0.5;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 50px);
}
.content .grid-content {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.content .grid-content .upload {
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
/*# sourceMappingURL=src/pages/importExport/upload.css.map */