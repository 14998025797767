



















































.vdiscount-table {
  width: 100%
  input {
    width: 90%
  }
  .ralign {
    text-align: right;
  }
}
