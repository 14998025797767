@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'








































































































































































.dialog{
  min-width : 500px;
  width: 100%;
  max-width: 50%;
  max-height: 80%;
  overflow: hidden;
}

.lg-section-content{
  //min-width: 550px
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;

  //border: 1px solid red;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
   justify-content: flex-start;
  // align-items: flex-start;
}

.attr-container{
   //border: 1px solid blue;
  // width:100%;
}

.choose-values-message{
  margin: 20px
}

.separator{
  height: 1px
  background-color: $grey-5
  width: 100%
}

.q-checkbox{
  flex-grow: 0
}

.lg-sparkline-set{
  background $grey-4
  width 60px
  margin: 5px
  padding: 5px
}

.boolean-attribute-container{
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid #ccc
  padding-bottom: 10px
  margin-bottom: 10px
}

.attribute-information{
  display: flex
  flex-direction: row
  align-items: center
}

.weights-container {
  display: flex
  flex-direction: column
}

.weights-container > div {
  display: flex;
  align-items: center
  justify-content: flex-start
}

.weight-value{
  width: 80px
  flex-grow: 2
}

.lg-sparkline-set, .weight-value {
  flex: 1
  text-align: center
}

.test1 {
  display: flex
  align-items: center
  justify-content: space-between
}

.test2 {
  margin: 20px
}
