.login-standby {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.565);
  z-index: 5000;
}
.login-standby div {
  width: 200px;
  text-align: center;
  color: #fff;
  font-size: 1rem;
}
.login-standby.login-standby-active {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-box {
  max-width: 400px;
}
.login-box .align-right {
  text-align: right;
}
/*# sourceMappingURL=src/components/Login.css.map */