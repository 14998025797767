label {
  padding-left: 0;
  padding-right: 0;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
.drawer-container {
  padding: 1rem;
  position: relative;
}
.drawer-container .rule-info {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}
.drawer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.drawer-line {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}
.drawer-separator {
  background-color: #808080;
  height: 2px;
  width: 80%;
  margin: 25px auto 15px auto;
  opacity: 0.5;
}
.drawer-section-title {
  font-size: 17px;
  font-weight: 500;
}
/*# sourceMappingURL=src/pages/pricing/SearchRefDrawer.css.map */