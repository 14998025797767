



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.historygrid {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: repeat(auto, minmax(30px, 1fr));
  gap: 6px 6px;

  align-items: center;
  justify-content: space-between;
  .historyheader {
    font-weight: bold;
    border-bottom: 1px solid gray;
    margin-bottom: 1rem;
  }
}

.ruleparams-fab {
  right 1rem
  bottom 1rem
  left: 1rem
  text-align center
}
.lg-part-panels {
  height: calc(100vh - 50px);
}
.productdetails {
  height: calc(100vh - 170px);
  width: 100%;
  display flex
  align-items: stretch
  .block {
    flex 1
  }
  .block.right {
    height 100%
    .panels {
      height: calc(100vh - 270px);
    }
  }
}
.inline-flex {
  display: inline-flex;
}

.q-table-container {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.img {
  cursor: pointer;
  padding: 10px;

  img {
    width: 100%;
  }
}

.selected {
  background: rgba(0, 0, 0, 0.1);
}

.content {
  width: 100%;
  height: calc(100vh - 50px);
}

.fullposition > div.q-card {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
}

.component-table {
  width: 100%;
  thead, tfoot {
    background-color white;
  }
  thead {
    border-bottom: 1px solid gray;
  }
  tfoot {
    border-top: 1px solid gray;
  }
  th.component-name {
    width: 140px;
  }
  th.component-action {
    width: 25px;
  }
  th.component-qty {
    width: 100px;
  }
  th.component-discount {
    width: 100px;
  }
  td.component-qty {
    text-align center;
  }
  td.component-label {
    overflow: hidden;
  }
  >>> .q-field--dense .q-field__control, >>> .q-field--dense .q-field__marginal, >>> .q-field--dense .q-field__append {
    height 30px
  }
  >>> .q-field--auto-height.q-field--dense .q-field__control {
    min-height unset;
  }
  >>> .q-field--auto-height.q-field--dense .q-field__native {
    min-height: unset;
  }
}
.file-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  .drop-circle {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f1f1f1;
  }
}

.draggable-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    cursor: pointer;
  }

  .placeHolder {
    opacity: 0.5;
  }

  .draggable {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
  }

  p {
    margin: 0;
  }
}
