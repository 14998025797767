table {
  border-collapse: collapse;
  table-layout: fixed;
  white-space: nowrap;
}
table tr td {
  border-bottom: solid 1px rgba(0,0,0,0.2);
  padding: 5px 30px;
}
table tr td .drop-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
table tr td .extend {
  font-size: 2rem;
  cursor: pointer;
}
table tr td .extend:hover {
  font-size: 3rem;
}
table tr td .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
table tr td .content .q-chip-right {
  margin-left: 0;
  margin-right: 0;
}
table tr td.topBottomLine {
  font-weight: bold;
}
/*# sourceMappingURL=src/components/Table.css.map */