














































































































.notifhead {
  border-bottom 1px solid gray
}
.lg-padding-1 {
  padding-left: 2rem;
}
