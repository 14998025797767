.action .add-user {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.highlight {
  background: rgba(0,0,0,0.1);
}
.fulltable {
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
}
.fulltable .q-table-middle,
.fulltable .q-table__middle {
  min-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
}
.fulltable .q-table-middle thead tr:first-child th,
.fulltable .q-table__middle thead tr:first-child th {
  position: sticky;
  position: --webkit-sticky;
  top: 0;
  z-index: 900;
  background-color: #ffa000;
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid $light;
}
.fulltable .q-table-bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
}
/*# sourceMappingURL=src/pages/settings/users/users.css.map */