@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'


























































































































































































.mainmenu {
  .lg-menu-drawer {
    .q-drawer {
      background: $grey-3;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px rgba(0,0,0,0.14), 0 1px 10px rgba(0,0,0,0.12);

    }
    .lg-menu-bottom {
       background-color: $primary
       color: white;
      .bug-report {
        // position: absolute;
        bottom: 50px;
        cursor: pointer;

      }
    }

    .userblock {
      min-height 50px
    }

  }
}
