












































































































.attr-form {
  padding: 1rem;
}
.ruleparams-fab {
  bottom: 1rem
  right 1rem
}
