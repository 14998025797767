.crc-boundaries,
.crc-params {
  width: 100%;
}
.crc-boundaries input,
.crc-params input {
  width: 100%;
}
.crc-boundaries .ralign,
.crc-params .ralign {
  text-align: right;
}
/*# sourceMappingURL=src/pages/pricing/RuleCRC.css.map */