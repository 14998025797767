





























































































































.locked {
  cursor: pointer;
}

.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.content {
  padding: 1rem;
}
