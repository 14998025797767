







































.pagination {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.tble-content {
    min-width 100%
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  white-space: nowrap;
  min-width 100%
  .item {
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .link {
    cursor: pointer;
  }

  tr {
    height: 50px;
    max-height: 50px;

    overflow:td {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      padding: 5px 30px;

      .drop-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .extend {
        font-size: 2rem;
        cursor: pointer;

        &:hover {
          font-size: 3rem;
        }
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .q-chip-right {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &.topBottomLine {
        opacity: 0.7;
        // font-weight bold
      }
    }
  }

  .headerCell {
    opacity: 0.5;
  }
}
