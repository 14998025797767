.pricing {
  flex: 1;
  max-width: 100%;
  text-align: right;
}
.pricing.wrapped span {
  display: block;
}
.pricing.unwrapped {
  white-space: nowrap;
}
.currency-cell {
  text-align: right;
}
.daterange {
  font-size: 0.7rem;
  text-align: center;
}
.op {
  opacity: 0.7;
}
/*# sourceMappingURL=src/components/attributesDisplay/pricing.css.map */