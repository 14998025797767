

































































































































































































































































































.function-row {
  padding-bottom 1rem
  overflow: auto;
  .thumbnails, .boundaries {
    display flex
    flex-wrap nowrap
  }
  .thumbnails {

  }
  .boundaries {
    .boundary {
      margin-left 110px
      width: 100px;
    }
  }
}
.function-block {
  width: 160px;
  min-height: 207px;
  min-width: 160px;
  background: white;
  margin-right: 10px;
  position relative
  .q-card-container {
    padding: 4px;
  }
  svg {
        width: 100%
        height: 150px
        transform-origin 50% 50%
        transform scale(1,-1)
  }
  &:not(:last-child)::after {
    content: ''
    position absolute
    right: -5px
    top 0
    width 0
    border-right 1px dashed red
    bottom: -10px
  }
  &.empty-block {
    display: flex; align-items: center;
  }
}
