






























































.dialog{
  min-width : 500px;
  width: 100%;
  max-width: 50%;
  max-height: 80%;
}
