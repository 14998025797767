.chip-step3 {
  margin: 5px;
  cursor: pointer;
}
.input-btn {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.input-btn .q-field {
  width: 100%;
}
.input-btn-sp {
  padding-bottom: 4px;
}
.header {
  padding: 24px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.2);
}
/*# sourceMappingURL=src/components/drawer/ImpExp/step3.css.map */