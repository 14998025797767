@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'











































































































































































































.action {
  .add-user {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}

.highlight {
  background: rgba(0, 0, 0, 0.1);
}

.fulltable {
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);

  .q-table-middle, .q-table__middle {
    min-height: calc(100vh - 100px);
    height: calc(100vh - 100px);

    thead tr:first-child th {
      position: sticky;
      position: --webkit-sticky;
      top: 0;
      z-index: 900;
      background-color: $secondary;
      color: white
      font-weight bold
      border-bottom: 1px solid $light;
    }
  }

  .q-table-bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
