p {
  padding-top: 1rem;
}
.drawer-container {
  padding: 1rem;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
/*# sourceMappingURL=src/pages/pricing/LoadSearchDrawer.css.map */