.drawer-padding {
  padding: 1rem;
}
.results {
  overflow: auto;
  max-height: calc(100vh - 210px);
}
.results,
.header {
  display: grid;
  grid-template-columns: 80px 60px auto 32px;
}
.results > div {
  padding: 2px 0;
  border-bottom: 1px solid #dfdfdf;
}
.header {
  border-bottom: 1px solid #808080;
}
.header div {
  font-weight: bold;
}
.content {
  padding: 1rem;
}
.ruleparams-fab {
  right: 1rem;
  bottom: 1rem;
}
/*# sourceMappingURL=src/pages/pricing/AddRuleAttr.css.map */