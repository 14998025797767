






























.segmentationcard {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  padding: 4px
  .tag {
    background-color: #fe452D;
    border-radius: 10px;
    color: white;
    text-align: center;
    padding: 2px 10px;
    font-variant: small-caps;
    font-weight: bold;
  }
  .family {
    font-size: 1rem;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li:not(:last-child)::after {
      display: inline-block;
      margin: 0 .25rem;
      content: "→";
    }
  }
  .helper {
    font-size: 1rem;
    color: #aaa;
    }
}

