@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'


















































































































.lg-app-header {
  background darken($primary, 30%)
}
.grow {
  flex 1
}
