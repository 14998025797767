









































































































































.graphmode{
  padding: 20px;
}
