








































































































































.maindrawer {
  display: flex;
  flex-direction: column;
  .drawer-container2 {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    .selection-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.lg-rule-actions {
  text-align: right;
  padding: 1rem;
}
