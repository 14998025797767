input[type="number"] {
  text-align: right;
}
#lg-pricing-spitter .q-splitter__after {
  padding-top: 50px;
}
#lg-pricing-spitter .q-splitter__separator > .q-splitter__separator-area {
  right: 0;
}
#lg-pricing-spitter .drawerbar {
  display: flex;
}
#lg-pricing-spitter .drawerbar > div:first-child {
  display: flex;
  background-color: #eee;
  flex-direction: column;
  height: calc(100vh - 51px);
}
#lg-pricing-spitter .drawerbar > div:nth-child(2) {
  flex: 1;
  overflow: auto;
}
.lg-sticky-scroll {
  overflow: unset;
}
.lg-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  border-top: 1px solid #808080;
  position: relative;
}
.lg-pagination .lg-pagination-status {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 3rem;
}
.lg-pagination .lg-actual-pagination {
  flex: 1;
  display: flex;
  justify-content: center;
}
.lg-pagination .lg-time-travel {
  padding-right: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #808080;
}
#grid-container {
  height: calc(100vh - 100px);
  width: 100%;
  overflow: auto;
  display: grid;
}
#grid-container.lg-commented-grid {
  height: calc(100vh - 158px);
}
#grid-container .grid {
  display: flex;
  flex-wrap: nowrap;
}
#grid-container .grid .grid-col {
  width: 150px;
  min-width: 150px;
}
#grid-container .grid .grid-col.nonpriced .grid-cell.comparable {
  background: #d2979e;
}
#grid-container .grid .grid-col.nonpriced .grid-cell.cell-first.comparable {
  background: #d2979e;
}
#grid-container .grid .grid-col.slavepart .grid-cell {
  background: #a2b5ca;
}
#grid-container .grid .grid-col.slavepart .grid-cell.cell-first {
  background: #a2b5ca;
}
#grid-container .grid .grid-col.active-search .grid-cell {
  background: #ff0;
}
#grid-container .grid .grid-col.active-search .grid-cell.cell-first {
  background: #ff0;
}
#grid-container .grid .grid-col.subnom .grid-cell.comparable {
  background: #db865e;
}
#grid-container .grid .grid-col.subnom .grid-cell.cell-first.comparable {
  background: #db865e;
}
#grid-container .grid .grid-col .grid-cell {
  height: 150px;
  overflow: hidden;
  white-space: nowrap;
  height: auto;
  border-right: 1px solid #808080;
}
#grid-container .grid .grid-col .grid-cell .lg-attr-group {
  min-width: 120px;
  padding: 0 1rem;
  font-variant: small-caps;
}
#grid-container .grid .grid-col .grid-cell.comparable {
  background: #a9a9a9;
}
#grid-container .grid .grid-col .grid-cell.comparable .cell-item:nth-child(even) {
  background: #9a9a9a;
}
#grid-container .grid .grid-col .grid-cell.cell-first {
  position: sticky;
  -webkit-position: sticky;
  top: 0;
  background: #fff;
  z-index: 1400;
}
#grid-container .grid .grid-col .grid-cell.cell-first.comparable {
  background: #bbb;
}
#grid-container .grid .grid-col .grid-cell.cell-first.comparable .cell-item:nth-child(even) {
  background: #a9a9a9;
}
#grid-container .grid .grid-col .grid-cell.cell-first .photo-cell {
  z-index: 4500;
}
#grid-container .grid .grid-col .grid-cell.cell-first .lg-attr-group {
  background: #fff;
  line-height: 1.2rem;
  margin-top: 2px;
}
#grid-container .grid .grid-col .grid-cell.cell-first li.validated-price {
  height: 46px;
  line-height: 38px;
  text-align: right;
  padding-right: 4px;
  border-top: 1px solid #808080;
}
#grid-container .grid .grid-col .grid-cell ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#grid-container .grid .grid-col .grid-cell ul li ul.collapsed {
  display: none;
}
#grid-container .grid .grid-col .grid-cell ul li.validated-price {
  height: 46px;
  margin-bottom: 4px;
}
#grid-container .grid .grid-col .grid-cell ul li.validated-price .q-if-addon {
  min-width: calc(2.2rem + 4px);
  text-align: left;
  padding-left: 2px;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell {
  height: 106px;
  padding-top: 6px;
  text-align: center;
  position: relative;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview {
  display: grid;
  align-items: stretch;
  grid-template-columns: 30px 1fr 30px;
  grid-template-areas: "purchasealert turnover growing" "numberofparts turnover same" "anomalies turnover shrinking";
  margin: 0 6px;
  height: 100px;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview >div {
  display: flex;
  align-items: center;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-purchasealert {
  grid-area: purchasealert;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-anomalies {
  grid-area: anomalies;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-numberofparts {
  grid-area: numberofparts;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-growing {
  grid-area: growing;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-shrinking {
  grid-area: shrinking;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-same {
  grid-area: same;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-turnover-margin {
  grid-area: turnover;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "margin-validated" "margin" "turnover";
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-turnover-margin .overview-turnover,
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-turnover-margin .overview-margin {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2em;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-turnover-margin .overview-turnover {
  grid-area: turnover;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-turnover-margin .overview-margin {
  grid-area: margin;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-turnover-margin .overview-margin-validated {
  grid-area: margin-validated;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell >.overview .overview-turnover-margin .overview-margin-validated > div {
  flex: 0.6;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell img {
  height: 100px;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell .lg-photos-dots {
  position: absolute;
  top: 4px;
  right: 16px;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell .lg-photos-dots .lg-photos-dot {
  background: #e0e0e0;
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 2px;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell .lg-photos-dots .lg-photos-dot:first-child {
  margin-left: 0;
}
#grid-container .grid .grid-col .grid-cell ul li.photo-cell .infozone {
  position: absolute;
  bottom: 0px;
  left: 14px;
  font-size: 1rem;
  color: #455a64;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item {
  height: 25px;
  padding: 0 4px;
  position: relative;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item.double-line {
  height: 38px;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-string {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .attributehistory {
  position: absolute;
  font-size: 1rem;
  top: 0px;
  left: 4px;
  right: 4px;
  bottom: 0;
  z-index: 1001;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-date,
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-boolean,
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-field {
  text-align: center;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-field .lg-boolean {
  margin-top: -0.5rem;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-field .q-field__control {
  height: 24px;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-amount,
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-number {
  text-align: right;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-amount .cell-unit,
#grid-container .grid .grid-col .grid-cell ul li .cell-item .cell-number .cell-unit {
  min-width: 2.2rem;
  display: inline-block;
  text-align: left;
  padding-left: 2px;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .proposed-price {
  position: absolute;
  display: flex;
  justify-content: space-between;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .proposed-price button {
/* font-size: 7px;*/
  font-weight: bold;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item .lg-input-attribute {
  border: 0;
  padding: 0;
  background: transparent;
  text-align: center;
  margin: 0;
  padding-left: 0.25rem;
}
#grid-container .grid .grid-col .grid-cell ul li .cell-item:nth-child(even) {
  background: #ebeff1;
}
#grid-container .grid .grid-col .grid-cell ul li.attr-set {
  padding-top: 0.6rem;
  min-height: 2rem;
}
#grid-container .grid .grid-col .grid-cell ul li.attr-set .attrset-list {
  border-top: 1px solid #808080;
  padding-top: 0.5rem;
}
#grid-container .grid .grid-col .grid-cell ul li.attr-set:first-child .attrset-list {
  border-top: none;
}
#grid-container .grid .grid-col.col-first {
  background: #d7dfe3;
  position: sticky;
  -webkit-position: sticky;
  left: 0;
  z-index: 1500;
  width: auto;
  max-width: 250px;
}
#grid-container .grid .grid-col.col-first .cell-item {
  text-align: right;
}
#grid-container .grid .grid-col.col-first .grid-cell.cell-first {
  z-index: 1520;
}
#grid-container .grid .grid-col.col-first div.attrset-first {
  position: absolute;
  margin-top: -0.8rem;
  padding: 2px 10px;
  z-index: 1510;
}
.lg-variation-2.photo-cell:after {
  content: '';
  position: absolute;
  border: 2px solid #21ba45;
  border-radius: 2px;
  bottom: -7px;
  left: 1rem;
  right: 1rem;
}
.lg-variation-0.photo-cell:after {
  content: '';
  position: absolute;
  border: 2px solid #c10015;
  border-radius: 2px;
  bottom: -7px;
  left: 1rem;
  right: 1rem;
}
#rightoggle {
  z-index: 1700;
}
#rightoggle .q-btn {
  display: block;
}
button.q-btn.q-btn-round.in-row {
  height: 18px;
  width: 18px;
  font-size: auto;
}
button.q-btn.q-btn-round.in-row.no-sort {
  color: $light !important;
}
button.q-btn.q-btn-round.in-row.sort-asc,
button.q-btn.q-btn-round.in-row.sort-desc {
  color: #455a64 !important;
}
.savetip {
  position: absolute;
  right: 2px;
  top: 2px;
}
.thingstosave {
  position: absolute;
  right: 6px;
  font-size: 0.6rem;
  top: -7px;
}
.error-container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.lg-part-segment {
  position: absolute;
  top: 3px;
  right: 3px;
}
.animated:active {
  background: #ffb333;
}
.animated:not(:active) {
  animation: shadow-drop-center 0.2s ease 0s 1 normal none;
}
.ellipsisAttr {
  overflow: hidden;
  text-overflow: ellipsis;
}
@-moz-keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.35);
  }
}
@-webkit-keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.35);
  }
}
@-o-keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.35);
  }
}
@keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.35);
  }
}
/*# sourceMappingURL=src/pages/pricing/Pricing.css.map */