





















































































































































































































#decisiongrid {
  width: 100%;
  thead {
    tr {
      td:first-child {
        width: 40px;
      }
    }
  }
}
.topTitle {
  display: block;
  padding: 30px;
  font-size: 2rem;
  opacity: 0.5;
}

.content {
  width: 100%;
}

.error-content {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.header {
  min-width: 400px;

  .title {
    display: flex;
  }
}

.icon {
  cursor: pointer;
}

.cell {
  display: flex;

  .operatorSelect {
    width: 100px;
  }

  .tagSelect {
    min-width: 300px;
    width: 100%;
  }
}
