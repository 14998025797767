








































































































































































































































































































































































































































































































































































.chartContainer {
  display none
  height calc(100vh - 100px)
  &.graphmode {
    display block
  }
  #hcharts {
    height calc(100vh - 100px)
  }
}
