




















































.lg-rule-comment {
  padding .4rem
}
.lg-comment {
  min-height 70vh
  height 70vh
}
