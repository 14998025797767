.segmentationcard {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  align-items: flex-start;
  padding: 4px;
}
.segmentationcard .tag {
  background-color: #fe452d;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  padding: 2px 10px;
  font-variant: small-caps;
  font-weight: bold;
}
.segmentationcard .family {
  font-size: 1rem;
  color: #333;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.segmentationcard .family li:not(:last-child)::after {
  display: inline-block;
  margin: 0 0.25rem;
  content: "→";
}
.segmentationcard .helper {
  font-size: 1rem;
  color: #aaa;
}
/*# sourceMappingURL=src/pages/productDetails/History/segmentation.css.map */