.bc {
  padding-left: 0.5rem;
  margin: 0;
  padding-right: 0.5rem;
}
.header {
  width: 100%;
  display: flex;
  align-items: stretch;
  padding-left: 0.4rem;
  margin: 0;
  padding-right: 0.4rem;
}
.header .card {
  margin: 10px;
  position: relative;
  flex: 1;
}
.header .card >>> .q-card__section {
  font-size: 1.5rem;
  text-align: center;
  height: 6rem;
}
.header .card >>> .q-card__section .title {
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  text-align: left;
}
.header .card >>> .q-card__section .minor {
  font-size: 0.8rem;
}
/*# sourceMappingURL=src/pages/productDetails/HeaderProduct/HeaderProduct.css.map */