p {
  padding-top: 1rem;
}
.drawer-container {
  padding: 1rem;
}
.drawer-container .decision,
.drawer-container .condition {
  box-shadow: 2px 2px 4px 2px #ccc;
}
.drawer-container .condition {
  border: 1px solid #ccc;
}
.drawer-container .condition .operation {
  padding: 0.5rem;
}
.drawer-container .condition .attribute {
  font-weight: bold;
  padding: 0.5rem;
  background: #f0f0f0;
}
.drawer-container .decision {
  font-weight: bold;
  border-radius: 50%;
  background: #f0f0f0;
  text-align: center;
  line-height: 1.8rem;
  width: 4rem;
  height: 4rem;
  margin: 1rem auto;
  background: #90ee90;
}
.drawer-container .downarrow {
  text-align: center;
  font-size: 2rem;
  margin: 1rem 0;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
/*# sourceMappingURL=src/pages/pricing/CostPlusPath.css.map */