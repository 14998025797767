.btnsp {
  margin-left: 3px;
}
.nobtn {
  height: 36px;
}
.doubleBtnEnd {
  display: flex;
  justify-content: flex-end;
}
.stepper {
  box-shadow: 0 0 0 rgba(0,0,0,0);
}
.stepper .q-stepper-header {
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.2);
  box-shadow: 0 1px 1px rgba(0,0,0,0.2);
}
.stepper .q-stepper-step-inner {
  padding: 0 !important;
  margin: 0;
}
.stepper .q-stepper-nav {
  margin: 0 !important;
  padding: 0;
}
.stepper .content {
  height: calc(100vh - 171px);
  overflow: auto;
}
.stepper .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 5px;
  bottom: 1rem;
  right: 0;
}
/*# sourceMappingURL=src/components/drawer/ImpExp/stepper.css.map */