.maindrawer {
  display: flex;
  flex-direction: column;
}
.maindrawer .drawer-container2 {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maindrawer .drawer-container2 .selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lg-rule-actions {
  text-align: right;
  padding: 1rem;
}
/*# sourceMappingURL=src/pages/pricing/Resegment.css.map */