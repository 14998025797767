.draggable-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.draggable-container .item {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 2rem;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.draggable-container .item .tag-selector {
  grid-area: 2/1/3/3;
}
.draggable-container .item .tag-selector select {
  width: 100%;
}
.draggable-container .placeHolder {
  opacity: 0.5;
}
.draggable-container .draggable {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid 1px rgba(0,0,0,0.25);
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}
.draggable-container p {
  margin: 0;
}
/*# sourceMappingURL=src/components/Droppable.css.map */