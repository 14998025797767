.settingheader {
  border-top: 1px solid #000;
  position: relative;
  margin: 20px 0;
  margin-left: 0.5rem;
}
.settingheader .title {
  position: absolute;
  top: -10px;
  display: inline-block;
  background: #fff;
  padding: 0 10px;
  margin: 0 10px;
}
.settingheader .recompute {
  position: absolute;
  right: 10px;
  top: -1rem;
}
.pivotselect {
  outline: 0;
  border: 0;
}
.accordeon-params {
  font-weight: bold;
  border-bottom: 1px solid #000;
}
.pivottype,
.multipivot-block {
  padding-right: 40px;
}
div.drawer-container {
  overflow: auto;
  height: calc(100vh - 255px);
}
div.drawer-container .rule-name {
  padding: 1rem;
}
div.drawer-container .ruleparams-fab {
  right: 1rem;
  bottom: 1rem;
}
div.drawer-container .lg-common-settings {
  padding-left: 0.5rem;
  padding-right: 2.5rem;
}
div.drawer-container .lg-other-settings {
  padding-left: 0.5rem;
}
div.drawer-container .lg-other-settings.lg-force-right {
  margin-right: -40px;
}
div.drawer-container .common-check {
  background-color: #f2f2f2;
  padding-left: 0.5rem;
  margin: 1px;
}
div.drawer-container .pivot-actions {
  display: flex;
  gap: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.1rem;
  padding: 1rem;
}
div.drawer-container .pivot-actions .autopivot {
  flex: 1;
}
/*# sourceMappingURL=src/pages/pricing/RuleParams.css.map */