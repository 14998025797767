

































.pricing {
  flex: 1
  max-width 100%
  text-align right
  &.wrapped {
    span {
      display: block
   }
  }
  &.unwrapped {
    white-space nowrap
  }
}
.currency-cell {
  text-align: right;
}
.daterange {
  font-size: .7rem;
  text-align center
}
.op {
  opacity: 0.7;
}
