






































































































































































































































































































































































































































































































































































































.create-segmentation-container {
  display: flex;
  align-items: center;
  width: 100%;
  //border:1px solid red;
  margin-bottom: 1em;
  padding: 0 1em;
}

.create-segmentation-input{
  flex: 1;
}

.create-segmentation-button {
  margin-left: 1em;
  height: 50px;
  width:50px;
  font-size: 0.8em;
}

.ctrl-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  //flex-wrap: wrap;
  align-items: center;
  //margin-bottom: 1em;
  padding: 0 1em;
}

.segmentation-button{
  width: 100%;
  margin-bottom: 1em;
  font-size: 0.84em;
  height: 50px;
}

.selected-text1
.selected-text2{
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom:0.5em;
  color: #ff8700;
  width: 100%;
  text-align: center;
}
.selected-text2{
  margin-top: 0.5em;
}

.content {
  display: flex;
  flex-direction: column;
  padding-top:  1em;
  border-bottom: 1px solid #ccc;
}

.drawerTitle {
  font-size: 1.2em;
  font-weight: bold;
  color: #444;
  width: 100%;
  text-align: center;
  margin-top: -0.25em;
  margin-bottom: 0.5em;
}

.add-icon{
  cursor: pointer;
  font-size:1.4em;
}
.add-icon:hover {
  transform: scale(1.2);
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  max-height: 400px !important;
  overflow-y: scroll;
  padding: 0 1em;
}

.item-type1
.item-type2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px;
  height: 100px;
  width:75px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 8px;
  &:hover {
    background-color: #f0f0f0;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0;
    width: 100%;
  }

  img {
    height: 50px;
    border: 1px solid #ccc;
    object-fit: contain;
    border-radius: 5px;
  }
}

.item-type1{
  flex-direction: column;
  position : relative;
  .close-icon {
    position: absolute;
    top: 3px;
    right:2px;
  }
}

.item-type2 {
  flex-direction: row;
  justify-content: center
  height: 40px;
  width: 115px;

  p {
    text-align: center;
    font-size: 0.7em;
    font-weight: bold;
  }
}

.close-icon {
  cursor: pointer;
  font-size: 1.1em;
  margin-left: 8px;
  &:hover {
    color: red;
  }
}
