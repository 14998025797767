@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'





















































































































.q-layout-page
  display flex
  .finder-container
    flex 1
  .file-detail
    background: $grey-12
    min-width: 300px
    width 300px
    .doughnut
      width: 100%
    .family-fab
      right: 1rem;
      bottom: 1rem;
