




















































































.draggable-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 2rem;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    .tag-selector {
      grid-area: 2 / 1 / 3 / 3;
      select { width: 100% }
    }
  }

  .placeHolder {
    opacity: 0.5;
  }

  .draggable {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
  }

  p {
    margin: 0;
  }
}
