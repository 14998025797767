>>> .lg-import-table table.q-table {
  width: 100%;
  max-width: 100%;
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
}
.content .action .add-user {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.content .inline-svg {
  width: 100%;
  height: 100%;
}
.content .inline-svg rect.bar-ok {
  fill: #21ba45;
  stroke: #fff;
}
.content .inline-svg rect.bar-error {
  fill: #c10015;
  stroke: #fff;
}
.content .inline-svg rect.bar-transmitted {
  stroke: #fff;
  fill: #81c784;
}
.content .inline-svg rect.bar-acknowledged {
  stroke: #fff;
  fill: #4caf50;
}
.content .inline-svg rect.bar-active {
  stroke: #fff;
  fill: #388e3c;
}
.content .inline-svg rect.bar-waiting {
  stroke: #fff;
  fill: #c8e6c9;
}
.content .inline-svg rect.bar-rejected {
  stroke: #fff;
  fill: #f44336;
}
.content .inline-svg rect.bar-inconsistent {
  stroke: #fff;
  fill: #d32f2f;
}
/*# sourceMappingURL=src/pages/importExport/ListImports.css.map */