.smallinput {
  width: 80px;
}
.smallselect {
  min-width: 80px;
}
.qtysep {
  font-size: 2rem;
}
.lg-attribute {
  height: 100%;
}
.lg-attribute .lg-attribute-content {
  display: flex;
  align-items: center;
}
.lg-attribute .lg-attribute-content .lg-attribute-right {
  justify-content: flex-end;
}
.lg-attribute .lg-attribute-content .lg-attribute-center {
  justify-content: center;
}
.lg-attribute .lg-attribute-content .lg-attribute-left {
  justify-content: flex-start;
}
.lg-attribute .lg-attribute-content .lg-attribute-label {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lg-attribute .lg-attribute-content .lg-attribute-value {
  height: 100%;
  flex: 1;
  display: flex;
  max-width: 100%;
  align-items: center;
}
.lg-attribute .lg-attribute-content .lg-attribute-value .lg-sparlines {
  height: 100%;
  flex: 1;
}
.lg-attribute .lg-attribute-content .lg-attribute-value .lg-sparlines .lg-sparkline {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.lg-attribute .lg-attribute-content .lg-attribute-value .lg-sparlines .lg-sparkline .status-badge {
  position: absolute;
  top: 4px;
}
.lg-attribute .lg-attribute-content .lg-attribute-value .lg-sparlines .lg-sparkline .lg-sparkline-set {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  overflow: hidden;
  background: #f5f5f5;
  height: 36px;
}
.history-owner {
  color: #808080;
  font-size: 0.6rem;
  font-style: italic;
  text-align: right;
}
.lg-attribute-timeserie-history {
  height: calc(100% - 42px);
}
.lg-attribute-timeserie-history .q-tab-panel {
  padding-left: 0;
  padding-right: 0;
}
.lg-attribute-timeserie-history .q-tab-panels {
  height: calc(100% - 38px);
}
.lg-attribute-timeserie-history .q-table tr {
  height: auto;
  min-height: auto;
}
.lg-attribute-timeserie-history .q-table tr td {
  padding: 0.2rem;
  height: auto;
}
.lg-attribute-timeserie-history .q-table tr td .q-input,
.lg-attribute-timeserie-history .q-table tr td .q-select {
  padding: 0;
}
.lg-attribute-timeserie-history .q-table tr td .q-select {
  margin-left: 0.2rem;
}
.lg-attribute-timeserie-history .q-table tr td.newvalue {
  display: flex;
}
.lg-attribute-timeserie-history .q-table tr td.newvalue >>> .q-field__control {
  padding: 0;
}
.lg-attribute-timeserie-history .q-table tr td:last-child,
.lg-attribute-timeserie-history .q-table tr th:last-child {
  width: 50px;
}
.lg-attribute-timeserie-history .q-table tbody .pricing {
  text-align: right;
}
.lg-attribute-timeserie-history .q-table tbody tr:first-child td {
  font-weight: bold;
}
/*# sourceMappingURL=src/components/attributesDisplay/attribute.css.map */