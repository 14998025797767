.mainmenu .lg-menu-drawer .q-drawer {
  background: #eee;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px rgba(0,0,0,0.14), 0 1px 10px rgba(0,0,0,0.12);
}
.mainmenu .lg-menu-drawer .lg-menu-bottom {
  background-color: #455a64;
  color: #fff;
}
.mainmenu .lg-menu-drawer .lg-menu-bottom .bug-report {
  bottom: 50px;
  cursor: pointer;
}
.mainmenu .lg-menu-drawer .userblock {
  min-height: 50px;
}
/*# sourceMappingURL=src/pages/Menu.css.map */