.finder-container {
  flex-wrap: nowrap;
}
.finder-container .finder-column {
  width: 30vw;
  max-width: 30vw;
  min-width: 30vw;
  border-right: 1px solid $light;
  position: relative;
}
.finder-container .finder-column div.q-list {
  position: relative;
  overflow: auto;
  height: 100%;
}
.finder-container .finder-column .finder-fab {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
/*# sourceMappingURL=src/components/Finder2.css.map */