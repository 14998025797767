.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.2rem 1rem;
}
.footer >>> .q-field {
  padding: 0;
  padding-right: 1rem;
}
.mainexport {
  height: calc(100vh - 98px);
}
.mainexport .col {
  padding: 1rem;
  height: 100%;
  overflow: auto;
}
.mainexport .col .padded {
  padding-top: 1rem;
}
.mainexport .col .lg-a-chip {
  display: inline-block;
  border: 1px solid #808080;
  padding: 0rem 0.5rem;
  border-radius: 1rem;
  line-height: 2rem;
  margin-right: 0.2rem;
}
.mainexport .col .lg-a-chip .inline-box {
  display: inline-block;
}
.mainexport .col .lg-a-chip .q-field {
  padding: 0;
  margin: 0 0.2rem;
}
.mainexport .col .lg-a-chip >>> .q-field__append,
.mainexport .col .lg-a-chip >>> .q-field__native,
.mainexport .col .lg-a-chip >>> .q-field__control {
  height: 26px;
  min-height: 26px;
}
.mainexport .col .lg-a-chip >>> .q-field__control {
  padding: 0 0.2rem;
}
.mainexport .col >>> .vue-treeselect--open .vue-treeselect__control {
  border: 0;
  background-color: #f2f2f2;
  border-radius: 0;
}
.mainexport .col >>> .vue-treeselect--open-below .vue-treeselect__menu {
  box-shadow: none;
  border: 0;
}
.mainexport .col >>> .vue-treeselect__menu {
  box-shadow: none;
  border: 0;
}
.mainexport .col .head1 {
  font-size: 1.4rem;
}
.mainexport .col .head1 .round {
  border-radius: 50%;
  width: 2rem;
  display: inline-block;
  text-align: center;
}
/*# sourceMappingURL=src/pages/importExport/toto.css.map */