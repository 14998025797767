















.chip-confirm {
  margin-left 10px
}
