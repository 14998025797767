@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'















































































































.drawer-content {
  padding: .5rem;
}
.lg-rule-actions {
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.fieldpad {
  margin-top: 2rem;
}
.htmlselect {
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.24);
  transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}
.htmlselect:hover {
  outline: none;
  border-color: #000
}
.htmlselect:focus {
  outline: none;
  border-bottom: 2px solid $primary;
  transition: transform 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}
.sectionheader {
  padding-top: 2rem;
}
.uomdiv {
  padding-top: 2rem;
    display: flex;
    gap: 1rem;
}
.price-banner {
  margin-top: .5rem
}
