@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'


























































































































































































































































































































































































































































































































.smallinput {
  width: 80px
}
.smallselect {
  min-width: 80px
}
.qtysep {
  font-size: 2rem
}
.lg-attribute {
  height 100%
  .lg-attribute-content {
    display flex
    align-items center
    .lg-attribute-right {
      justify-content flex-end
    }
    .lg-attribute-center {
      justify-content center
    }
    .lg-attribute-left {
      justify-content flex-start
    }
    .lg-attribute-label {
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .lg-attribute-value {
      height 100%
      flex: 1;
      display flex
      max-width: 100%;
      align-items center
      .lg-sparlines {
        height 100%
        flex 1
        .lg-sparkline {
          height 100%
          display flex
          align-items center
          position relative
          .status-badge {
            position: absolute
            top: 4px;
          }
          .lg-sparkline-set {
            writing-mode: vertical-rl;
            transform: rotate(-180deg);
            overflow hidden
            background $grey-2
            height 36px
          }
        }
      }
    }
  }
}
.history-owner {
  color: gray;
  font-size: .6rem;
  font-style: italic;
  text-align: right;
}
.lg-attribute-timeserie-history {
  height: calc(100% - 42px);
  .q-tab-panel {
    padding-left 0
    padding-right 0
  }
  .q-tab-panels {
    height: calc(100% - 38px);;
  }
  .q-table {
    tr {
      height auto
      min-height auto

      td {
        padding .2rem
        height auto
        .q-input, .q-select {
          padding: 0;
        }
        .q-select {
          margin-left: .2rem
        }
        &.newvalue {
          display flex
          >>> .q-field__control {
            padding: 0
          }
        }
      }
      td:last-child, th:last-child {
        width: 50px;
      }
    }
    tbody {
      .pricing {
        text-align right
      }
      tr:first-child {
        td {
          font-weight bold
        }
      }
    }
  }
}
