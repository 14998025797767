.locked {
  cursor: pointer;
}
.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.content {
  padding: 1rem;
}
/*# sourceMappingURL=src/components/drawer/tags/tagsDrawer.css.map */