


















































































































.drawer-content {
  padding: .5rem;
}
.lg-rule-actions {
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.lg-treeselect {
  padding-top: 2rem;
}
