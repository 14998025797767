














































































































































































































































































































.treeSelect {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.searchDiv{
  display: flex;
  flex-direction: row;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top:-10px;
}
.search {
  width: 100%;
  height: 35px;
  border: none;
  padding-left: 10px;
  font-size: 16px;
  margin-left: 2px;
}
.icon {
  margin: 0px;
  padding: 0px;
  width: 50px;
}
.tree {
  border: 1px solid #e0e0e0;
  flex-grow: 1;
  height: calc(100vh - 555px);
  overflow-y: auto;
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  margin-bottom:15px;
}

.carouselBig{
  position: fixed;
  width: 90%;
  height: 90%;
  max-width: 600px;
  max-height: 400px;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .cross{
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px;
    padding: 0px;
    width: 30px;
    height: 30px;
  }
  .title {
    font-size: 20px;
    margin-top:10px
  }

  .imgs {
    margin-top:-10px;
    height: 100%;

    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
  }

  .imgs {
    display: flex;
    flex-direction: row;
  }

  .img{
    border-radius: 5px;
    width: 65%;
    cursor: pointer;
    object-fit: contain;
  }
}

.carousel{
   .topLine{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top:5px;
    .ico{
      margin-top:-18px;
      width: 40px;
      height: 40px;
    }
  }
  .imgs {
    margin-top:-10px;
    border: 1px solid #e0e0e0;
    height: 150px;
    overflow-y: auto;
    border-radius: 5px;

    padding: 5px 10px;
    max-height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
  }

  .imgs {
    display: flex;
    flex-direction: row;
  }

  .img{
    border-radius: 5px;
    width: 65%;
    cursor: pointer;
    object-fit: contain;
  }
}
