.drawer-container {
  padding: 0.5rem;
}
.drawer-container div.exportlist {
  display: grid;
  grid-template-columns: 100px 1fr;
}
.drawer-container div.exportlist .export-btn {
  min-width: 80px;
}
.drawer-container div.exportlist .optionlist {
  display: flex;
  flex-direction: column;
}
/*# sourceMappingURL=src/pages/pricing/ExportsRule.css.map */