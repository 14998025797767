

















































































































.chip-step3 {
  margin: 5px;
  cursor: pointer;
}

.input-btn {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.input-btn .q-field {
  width: 100%;
}

.input-btn-sp {
  padding-bottom: 4px;
}

.header {
  padding: 24px;
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

