.container {
  width: 100%;
  height: 100%;
}
.link {
  stroke: #555;
  stroke-width: 2px;
}
.node {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.node text {
  font: 12px sans-serif;
  alignment-baseline: middle;
  user-select: none;
}
.node circle,
.node path {
  fill: #fff;
}
.node:hover circle,
.node:hover path {
  stroke: #00f;
}
.node text.material-icons {
  font-family: 'Material Icons';
  font-size: 25px;
}
/*** SELECTED ***/
.selected circle,
.selected path {
  stroke: #00f !important;
  fill: #cdcdfa !important;
}
.link.selected {
  stroke: #00f !important;
  stroke-width: 3px;
}
/*** HIGHLIGHTED ***/
.highlighted circle,
.highlighted path {
  fill: #c7ffc5;
  stroke: rgba(6,146,1,0.5);
}
.link.highlighted {
  stroke: rgba(6,146,1,0.5);
  stroke-width: 3px;
}
/*** ORANGE HIGHLIGHT FOR UPPER SIBLINGS ***/
.upper-sibling-highlighted circle,
.upper-sibling-highlighted path {
  fill: #fde1ad;
  stroke: rgba(255,140,0,0.5);
}
.link.upper-sibling-highlighted {
  stroke: rgba(255,140,0,0.5);
  stroke-width: 3px;
}
/*** ERROR ***/
.error circle,
.error path {
  fill: #ffe9e9;
  stroke: rgba(255,0,0,0.5);
}
/*# sourceMappingURL=src/pages/pricing/costPlus/SvgTree.css.map */