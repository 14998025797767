.pagination {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.tble-content {
  min-width: 100%;
}
table {
  border-collapse: collapse;
  table-layout: fixed;
  white-space: nowrap;
  min-width: 100%;
}
table .item:hover {
  background-color: rgba(0,0,0,0.1);
}
table .link {
  cursor: pointer;
}
table tr {
  height: 50px;
  max-height: 50px;
}
table tr overflow:td {
  border-bottom: solid 1px rgba(0,0,0,0.2);
  padding: 5px 30px;
}
table tr overflow:td .drop-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
table tr overflow:td .extend {
  font-size: 2rem;
  cursor: pointer;
}
table tr overflow:td .extend:hover {
  font-size: 3rem;
}
table tr overflow:td .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
table tr overflow:td .content .q-chip-right {
  margin-left: 0;
  margin-right: 0;
}
table tr overflow:td.topBottomLine {
  opacity: 0.7;
}
table .headerCell {
  opacity: 0.5;
}
/*# sourceMappingURL=src/components/grid/Grid.css.map */