














































































.drawer-container {
  div.incontainer {
    padding 1rem
    height calc(100vh - 100px)
    overflow auto
  }
}
