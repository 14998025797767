





















































































.bc {
  padding-left: .5rem;
  margin: 0;
  padding-right: .5rem;
}
.header {
  width 100%
  display: flex;
  align-items: stretch;
  padding-left: .4rem;
  margin: 0;
  padding-right: .4rem;

  .card {
    margin: 10px;
    position: relative;
    flex 1
    >>> .q-card__section {
      font-size: 1.5rem;
      text-align: center;
      height: 6rem;
      .title {
        position: absolute;
        bottom: 0;
        font-size: 1rem;
        text-align: left;
      }

      .minor {
        font-size: 0.8rem;
      }
    }
  }
}