

















































































































































































.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.modaleCard {
  width:100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  overflow: hidden;
}

.cardAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid grey;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.activeLoop{
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
