/*
.attributeTable {
  height: calc(100vh - 540px);
  margin: 15px 15px;
}
*/
.chart {
  margin-left: -5px;
  width: calc(100% - 10px);
}
.placeholder {
  margin-top: 10px;
  text-align: center;
  height: 205px;
  line-height: 150px;
  border: 1px solid #bbb;
  overflow: hidden;
}
.bottom {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}
.bottom .q-btn {
  margin: 5px;
}
/*# sourceMappingURL=src/pages/products/drawer/detailsProductDrawer.css.map */