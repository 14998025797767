































































































.sub-content {
  padding: 24px;
}

.content-step2 {
  width: 100%;
}
