.costpluspath .decision,
.costpluspath .condition {
  box-shadow: 2px 2px 4px 2px #ccc;
}
.costpluspath .condition {
  border: 1px solid #ccc;
}
.costpluspath .condition .operation {
  padding: 0.5rem;
}
.costpluspath .condition .attribute {
  font-weight: bold;
  padding: 0.5rem;
  background: #f0f0f0;
}
.costpluspath .decision {
  font-weight: bold;
  border-radius: 50%;
  background: #f0f0f0;
  text-align: center;
  line-height: 1.8rem;
  width: 4rem;
  height: 4rem;
  margin: 1rem auto;
  background: #90ee90;
}
.costpluspath .downarrow {
  text-align: center;
  font-size: 2rem;
  margin: 1rem 0;
}
/*# sourceMappingURL=src/components/CostPlusPathRenderer.css.map */