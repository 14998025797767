







































p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
  .actualcomment {
    height: 100%
    padding: 1rem
    background: #f9f9f9;
  }
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
